import React, { useEffect, useState } from 'react';
// import { Link } from 'gatsby';
import { navigate } from 'gatsby';
import { Capacitor } from '@capacitor/core';
import { useAtom } from 'jotai';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import GoogleIcon from '@mui/icons-material/Google';
import AppleIcon from '@mui/icons-material/Apple';
import EmailIcon from '@mui/icons-material/AlternateEmail';
import PasswordIcon from '@mui/icons-material/Password';
import { Auth } from '@layouts';
import { LinkWithQuery } from '@components';
import useUser from '@hooks/useUser';
import { appVersion as appVersionState } from 'utils/store';
import useABTesting from 'hooks/useABTesting';

const SignIn = () => {
  const { t } = useABTesting();
  const [appVersion] = useAtom(appVersionState);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { session, signIn, loginGoogle, loginApple } = useUser();

  useEffect(() => {
    if (session) {
      navigate('/');
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email').trim();
    const password = data.get('password').trim();
    signIn.mutate({ email, password });
  };

  const handleTogglePassword = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleGoogleLogin = () => {
    loginGoogle.mutate();
  };

  const handleAppleLogin = () => {
    loginApple.mutate();
  };

  const isLoading =
    signIn.isLoading || loginGoogle.isLoading || loginApple.isLoading;
  const isError = signIn.isError || loginGoogle.isError || loginApple.isError;
  const error = signIn.error || loginGoogle.error || loginApple.error;

  const isOS = Capacitor.getPlatform() === 'ios';

  const textFieldStyle = {
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      overflow: 'hidden',
    },
  };
  return (
    <Auth>
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          color="primary"
          component="h1"
          align="left"
          variant="h4"
          sx={{ width: '100%', mb: 1 }}
        >
          {t('pages.sign_in.title')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate>
          <TextField
            margin="normal"
            required
            fullWidth
            sx={textFieldStyle}
            id="email"
            placeholder={t('components.input.email.placeholder')}
            name="email"
            autoComplete="email"
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            sx={textFieldStyle}
            name="password"
            placeholder={t('components.input.password.placeholder')}
            type={passwordVisible ? 'text' : 'password'}
            id="password"
            autoComplete="current-password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PasswordIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTogglePassword}
                    edge="end"
                  >
                    {passwordVisible ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <LinkWithQuery to="/forgot-password">
            <Typography variant="body2" align="right">
              {t('pages.sign_in.forgot_password')}
            </Typography>
          </LinkWithQuery>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isLoading}
            sx={{ mt: 3, mb: 2 }}
          >
            {t('pages.sign_in.button')}
          </Button>
          {isError ? (
            <Typography
              sx={{ mb: 2 }}
              align="center"
              color="error"
              variant="body2"
            >
              {error.message}
            </Typography>
          ) : null}
          <LinkWithQuery to="/sign-up">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Typography variant="body2" align="center">
                {t('pages.sign_in.sign_up_text')}
              </Typography>
              <Typography
                color="primary"
                sx={{ display: 'inline', textDecoration: 'underline' }}
                fontWeight={600}
              >
                {t('pages.sign_in.sign_up_button')}
              </Typography>
            </Stack>
          </LinkWithQuery>
          <Divider sx={{ my: 2 }}>
            <Typography variant="caption">{t('pages.sign_in.or')}</Typography>
          </Divider>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
          >
            <IconButton
              aria-label="Google Login"
              size="large"
              sx={{
                backgroundColor: '#000000',
                borderRadius: '0.5rem',
                '&:hover': { backgroundColor: '#666666' },
              }}
              onClick={handleGoogleLogin}
            >
              <GoogleIcon fontSize="large" htmlColor="#ffffff" />
            </IconButton>
            {isOS ? (
              <IconButton
                aria-label="Apple Login"
                size="large"
                sx={{
                  backgroundColor: '#000000',
                  borderRadius: '0.5rem',
                  '&:hover': { backgroundColor: '#666666' },
                }}
                onClick={handleAppleLogin}
              >
                <AppleIcon fontSize="large" htmlColor="#ffffff" />
              </IconButton>
            ) : null}
          </Stack>
          {/* <Box sx={{ mt: 2 }}>
            <Link to="/">
              <Typography
                sx={{ textDecoration: 'underline' }}
                variant="body2"
                align="center"
              >
                {t('pages.sign_in.not_yet')}
              </Typography>
            </Link>
          </Box> */}
          {appVersion ? (
            <Typography
              variant="caption"
              align="center"
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                opacity: 0.5,
              }}
            >
              {appVersion}
            </Typography>
          ) : null}
        </Box>
      </Box>
    </Auth>
  );
};

export default SignIn;
